import { ProductResponse } from '@/models/Product'

interface ProductHelper {
  getProductColorList: () => Array<string>
  getProductColor: (color: ProductResponse['colour']) => string
}
/**
 * @return {ProductHelper}
 */
export function useProductHelper(): ProductHelper {
  const productColors = ['#46F0C8', '#46beff', '#ffa938', '#ff7864', '#080809']

  /**
   * Get list of all possible product colors
   * @return {Array<string>} list with color strings
   */
  function getProductColorList(): Array<string> {
    return productColors
  }

  /**
   * Check if color is part of productColors
   * @param {Product.colour} color
   * @return {string} return color or default color
   */
  function getProductColor(color: ProductResponse['colour']): string {
    return color && productColors.includes(color) ? color : productColors[productColors.length - 1]
  }

  return {
    getProductColorList,
    getProductColor,
  }
}
