<template>
  <div class="content-container">
    <MdPreview
      v-if="product?.detailedDescription"
      v-model="product.detailedDescription"
      language="en-US"
      no-iconfont
    />
    <p
      v-else
      class="mb-0"
    >
      {{ t('noDescription') }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { MdPreview } from 'md-editor-v3'
import 'md-editor-v3/lib/style.css'

import { useProductStore } from '@/store/products'

const { t } = useI18n()
const productStore = useProductStore()

const { product } = storeToRefs(productStore)
</script>

<i18n lang="yaml">
de:
  noDescription: Keine Beschreibung verfügbar.
</i18n>
