import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'

import { createPinia, storeToRefs } from 'pinia'
import { initKeycloak, isLoggedIn, loginToKeycloak } from './plugins/keycloak'
import DefaultLayout from '@/layouts/default.vue'
import CleanLayout from '@/layouts/clean.vue'
import i18n from './plugins/i18n'
import vuetify, { setPrimaryColor } from './plugins/vuetify'
import { OrganizationPublicResponse } from '@/models/Organization'

import { useMyOrganizationStore } from '@/store/myOrganizations'
import { useLoadingStore } from '@/store/loading'
import { useActiveUserStore } from './store/activeUser'
import { register as registerSwiper } from 'swiper/element/bundle'
import { ResolveTenantResponse } from './models/Tenant'
import { Routes } from './models/enums/Routes'
import { useTenantStore } from './store/tenants'
import { useFavicon } from '@vueuse/core'

/**
 * checkTenant
 * @return {Promise<ResolveTenantResponse>}
 */
async function checkTenant(): Promise<ResolveTenantResponse> {
  try {
    const hostname = import.meta.env.DEV && import.meta.env.VITE_DEV_HOSTNAME !== undefined ? import.meta.env.VITE_DEV_HOSTNAME : window.location.hostname
    const response = await fetch(`/api/tenants/resolve?${new URLSearchParams({ hostname }).toString()}`)

    if (response.status === 200) {
      return await response.json()
    }
    return Promise.reject(`Resolve tenant failed: ${response.status} -> '${response.statusText}'`)
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * initVueApp
 * @param {ResolveTenantResponse} tenant
 */
async function initVueApp(tenant: ResolveTenantResponse): Promise<void> {
  // Register Vue
  const pinia = createPinia()
  const app = createApp(App)
  app.use(pinia)
  app.use(vuetify)
  app.use(i18n)

  // layouts have to be registered globally to be used in the app
  app.component('DefaultLayout', DefaultLayout)
  app.component('CleanLayout', CleanLayout)

  // register Swiper custom elements
  registerSwiper()

  // set tenant theming
  setPrimaryColor(tenant.theming.primaryColor)

  // organization checks have to happen before router init, to ensure correct router guard behaviour
  if (isLoggedIn()) {
    await checkActiveOrganization()
  }

  app.use(router)
  app.mount('#app')

  useFavicon(tenant.theming.favicon)

  const tenantStore = useTenantStore()
  tenantStore.setTenant(tenant)
}

/**
 * checkActiveOrganization
 */
async function checkActiveOrganization(): Promise<void> {
  const myOrganizationStore = useMyOrganizationStore()
  const activeUserStore = useActiveUserStore()
  const tenantStore = useTenantStore()
  const { myOrganizations } = storeToRefs(myOrganizationStore)
  const { activeUser } = storeToRefs(activeUserStore)
  const activeUserId: string | null = localStorage.getItem('activeUserId')
  const storedOrganizationId: OrganizationPublicResponse['id'] | null = localStorage.getItem('activeOrganizationId')
  const storedTenantOrganizationId: OrganizationPublicResponse['id'] | null = localStorage.getItem('activeTenantOrganizationId')
  const loadingStore = useLoadingStore()

  loadingStore.setAppLoading(true)
  try {
    await activeUserStore.GET()

    if (myOrganizations.value.length === 1) {
      // If there is only one organization, set it as active
      await myOrganizationStore.setActiveOrganization(myOrganizations.value[0].organizationId)
    } else if (storedOrganizationId && activeUserId && activeUserId === activeUser?.value?.id) {
      // Set active organization if last logged in user was the same
      await myOrganizationStore.setActiveOrganization(storedOrganizationId)
    } else if (storedTenantOrganizationId && activeUserId && activeUserId === activeUser?.value?.id) {
      // Set active tenant if last logged in user was the same
        await tenantStore.setActiveTenant(storedTenantOrganizationId)
    }
  } catch (e) {
    Promise.reject(e)
  } finally {
    loadingStore.setAppLoading(false)
  }
}

/**
 * checkForInvitationCode
 */
function checkForInvitationCode(): void {
  const params = new URLSearchParams(window.location.search)
  const invitationCode = params.get('invitationCode') || localStorage.getItem('invitationCode')

  if (invitationCode) {
    if (isLoggedIn()) {
      localStorage.removeItem('invitationCode')
      router.push({
        name: Routes.INVITATIONS,
        query: {
          invitationCode,
        },
      })
    } else {
      localStorage.setItem('invitationCode', invitationCode)
      loginToKeycloak()
    }
  }
}

const tenantResponse = await checkTenant()
await initKeycloak(tenantResponse)
await initVueApp(tenantResponse)
checkForInvitationCode()
