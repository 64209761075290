<template>
  <v-card
    variant="flat"
    class="d-flex flex-column pt-6 pb-4 px-8 text-center h-100"
  >
    <template #title>
      <h4 class="mt-1 text-wrap">
        <slot name="title" />
      </h4>
    </template>
    <template
      v-if="$slots.subtitle"
      #subtitle
    >
      <slot name="subtitle" />
    </template>
    <template
      v-if="$slots.text"
      #text
    >
      <slot name="text" />
    </template>
    <div
      v-if="$slots.actions"
      class="mx-auto"
    >
      <slot name="actions" />
    </div>
  </v-card>
</template>
