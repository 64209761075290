<template>
  <div
    v-if="bundles.length > 0"
    class="bundles"
  >
    <v-list
      v-for="(bundle, i) in bundles"
      :key="i"
      class="bundles-overview__bundle py-4 mb-4"
    >
      <v-list-group color="primary">
        <template #activator="{ isOpen, props }">
          <v-list-item
            class="bundles-overview__bundle-header"
            :ripple="false"
            @click="handleClick(bundle.id)"
          >
            <template #prepend>
              <BundleAvatar />
            </template>
            <v-list-item-title>
              <h3 class="mb-1">{{ bundle.name }}</h3>
            </v-list-item-title>
            <v-list-item-subtitle class="bundles-overview__bundle-subtext">
              <span v-if="bundle.providerOrganization?.name"> {{ bundle.providerOrganization.name }} </span>
              <span v-if="bundle.createdAt">
                {{
                  t('createdAt', {
                    d: format(new Date(bundle.createdAt), 'dd.MM.yyyy'),
                  })
                }}
              </span>
            </v-list-item-subtitle>
            <template #append>
              <StatusChip
                class="mr-3"
                :color="getStatusSetFromStatusKey(bundle.contractStatus)?.color"
                :icon="getStatusSetFromStatusKey(bundle.contractStatus)?.icon"
                :outlined="getStatusSetFromStatusKey(bundle.contractStatus)?.outlined"
              >
                {{ t(`bundleStatus.${bundle.contractStatus}`) }}
              </StatusChip>
              <AccessStatusToggleButton
                v-if="bundle.contractStatus === StatusTypes.APPROVED"
                :toggle-btn="bundle.accessStatus?.access"
                :tooltips="{ active: t('bundlesOverview.toggleBtn.tooltips.active'), inactive: t('bundlesOverview.toggleBtn.tooltips.inactive') }"
              />
              <IconButton
                :icon="isOpen ? Icons.CHEVRON_UP : Icons.CHEVRON_DOWN"
                class="ml-3"
                v-bind="props"
                @click.stop
              />
            </template>
          </v-list-item>
        </template>
        <div class="px-6 mt-4">
          <v-divider inset />
          <ProductList
            :products="bundle.bundleProducts"
            @handle-product-click="handleProductClick"
          />
        </div>
      </v-list-group>
    </v-list>
  </div>
</template>

<script lang="ts" setup>
import { BundleProductResponse, BundleResponse } from '@/models/Bundle'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { format } from 'date-fns'
import ProductList from '../products/ProductList.vue'
import { Routes } from '@/models/enums/Routes'
import { OrganizationTypes } from '@/models/enums/OrganizationTypes'
import { Icons } from '@/models/enums/IconTypes'
import AccessStatusToggleButton from './AccessStatusToggleButton.vue'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { useStatusHelper } from '@/composables/useStatusHelper'
import StatusChip from '../StatusChip.vue'
import IconButton from '../baseComponents/buttons/IconButton.vue'
import BundleAvatar from '@/components/bundles/BundleAvatar.vue'

const componentProps = defineProps({
  bundles: { type: Array<BundleResponse>, required: true },
  bundleLink: { type: String, required: true },
})

const { t } = useI18n()
const router = useRouter()

const { getStatusSetFromStatusKey } = useStatusHelper()

/**
 * handleClick
 * @param {bundleId} bundleId
 */
function handleClick(bundleId: BundleResponse['id']): void {
  if (componentProps.bundleLink === OrganizationTypes.CONSUMER) {
    router.push({ name: Routes.CONSUMER_BUNDLES_PRODUCTS, params: { bundleId } })
  } else if (componentProps.bundleLink === OrganizationTypes.PROVIDER) {
    router.push({ name: Routes.PROVIDER_BUNDLES_PRODUCTS, params: { bundleId } })
  }
}

/**
 * handleProductClick
 * @param {productId} productId
 */
function handleProductClick(productId: BundleProductResponse['id']): void {
  if (componentProps.bundleLink === OrganizationTypes.CONSUMER) {
    router.push({ name: Routes.CONSUMER_PRODUCTS_INFOS, params: { productId } })
  } else if (componentProps.bundleLink === OrganizationTypes.PROVIDER) {
    router.push({ name: Routes.PROVIDER_PRODUCTS_DETAILS, params: { productId } })
  }
}
</script>
<style lang="scss" scoped>
.bundles {
  width: 100%;

  .bundles-overview {
    &__bundle {
      &-header {
        :deep(.v-list-item__overlay) {
          opacity: 0;
        }
      }

      &-subtext {
        span {
          position: relative;
          padding: 0 0.5rem;
          font-size: $font-size-xs;

          &:first-child {
            padding-left: 0;
            font-weight: bold;
          }

          &:not(:last-child)::after {
            content: '';
            border-left: 1.5px solid rgb(var(--v-theme-black));
            height: 1rem;
            right: 0;
            top: -0.1rem;
            position: absolute;
          }
        }
      }
    }
  }
}
</style>

<i18n lang="yaml">
de:
  bundlesOverview:
    apis: '{a} API | {a} API-Produkte'
    toggleBtn:
      tooltips:
        active: Zugriff auf die App ist freigegeben.
        inactive: Zugriff auf die App ist gesperrt.
</i18n>
