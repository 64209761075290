<template>
  <v-list
    v-for="organizationRelation in organizationRelations"
    :key="organizationRelation[organizationContext].id"
    class="mb-4 pt-0 pb-0"
  >
    <v-list-item
      class="pa-6"
      @click="emit('handleClick', organizationRelation[organizationContext].id)"
    >
      <template #prepend>
        <v-avatar
          color="primary"
          rounded
          >{{ getFirstLetterUpperCase(organizationRelation[organizationContext].name) }}</v-avatar
        >
      </template>
      <template #title>
        <h3 class="mb-0">{{ organizationRelation[organizationContext].name }}</h3>
      </template>
      <template
        v-if="
          organizationRelation.status === StatusTypes.INACTIVE ||
          organizationRelation.status === StatusTypes.INITIAL ||
          organizationRelation.status === StatusTypes.IN_TERMINATION
        "
        #append
      >
        <StatusChip
          class="mx-4"
          :color="getStatusSetFromStatusKey(organizationRelation.status)?.color"
          :icon="getStatusSetFromStatusKey(organizationRelation.status)?.icon"
        >
          {{ t(`organizationRelationStatus.${organizationRelation.status}`) }}
        </StatusChip>
      </template>
    </v-list-item>
  </v-list>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { useFormatHelper } from '@/composables/useFormatHelper'
import { OrganizationRelationResponse } from '@/models/Organization'
import { useStatusHelper } from '@/composables/useStatusHelper'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { OrganizationTypes } from '@/models/enums/OrganizationTypes'
import StatusChip from '@/components/StatusChip.vue'

const { getFirstLetterUpperCase } = useFormatHelper()
const { t } = useI18n()
const emit = defineEmits(['handleClick'])
const { getStatusSetFromStatusKey } = useStatusHelper()

defineProps({
  organizationRelations: { type: Array<OrganizationRelationResponse>, required: true },
  organizationContext: { type: String as PropType<OrganizationTypes>, required: true },
})
</script>
