<template>
  <v-form
    ref="productForm"
    v-model="createProductForm"
    @submit.prevent="emit('submit', product)"
  >
    <DialogDefaultLayout>
      <template #content>
        <h1 class="mb-4">{{ productObject ? t('product.edit.headline') : t('product.create.headline') }}</h1>
        <p
          v-if="!productObject"
          class="mb-4"
        >
          {{ t('product.create.copy') }}
        </p>
        <v-item-group
          v-model="product.publiclyAvailable"
          mandatory
          class="d-flex space-between-content mb-8 availability-selector"
        >
          <v-item
            v-slot="{ isSelected, toggle }"
            :value="true"
          >
            <v-card
              class="text-center w-100 mr-4"
              variant="flat"
              color="secondary"
              @click="toggle"
            >
              <template #prepend>
                <v-tooltip
                  :text="t('product.public.tooltip')"
                  location="top"
                  transition="fade-transition"
                  :max-width="200"
                >
                  <template #activator="{ props }">
                    <v-icon
                      v-bind="props"
                      :icon="Icons.CIRCLE_INFORMATION"
                    ></v-icon>
                  </template>
                </v-tooltip>
              </template>
              <template #title>
                <DefaultAvatar
                  size="48"
                  class="mt-2 mb-4"
                  :color="PlattformColors.INFO"
                >
                  <v-icon size="24">{{ Icons.GLOBE }}</v-icon>
                </DefaultAvatar>
                <p class="highlight mb-0">{{ t('product.public.headline') }}</p>
              </template>
              <template #append>
                <v-icon
                  color="primary"
                  :icon="isSelected ? Icons.CHECKBOX_ON : Icons.CHECKBOX_OFF"
                ></v-icon>
              </template>
            </v-card>
          </v-item>
          <v-item
            v-slot="{ isSelected, toggle }"
            :value="false"
          >
            <v-card
              class="text-center w-100"
              variant="flat"
              color="secondary"
              @click="toggle"
            >
              <template #prepend>
                <v-tooltip
                  :text="t('product.private.tooltip')"
                  location="top"
                  transition="fade-transition"
                  :max-width="200"
                >
                  <template #activator="{ props }">
                    <v-icon
                      v-bind="props"
                      :icon="Icons.CIRCLE_INFORMATION"
                    ></v-icon>
                  </template>
                </v-tooltip>
              </template>
              <template #title>
                <DefaultAvatar
                  size="48"
                  class="mt-2 mb-4"
                  :color="PlattformColors.INFO"
                >
                  <v-icon size="24">{{ Icons.LOCK }}</v-icon>
                </DefaultAvatar>

                <p class="highlight mb-0">{{ t('product.private.headline') }}</p>
              </template>
              <template #append>
                <v-icon
                  color="primary"
                  :icon="isSelected ? Icons.CHECKBOX_ON : Icons.CHECKBOX_OFF"
                ></v-icon>
              </template>
            </v-card>
          </v-item>
        </v-item-group>
        <InfoBox
          v-if="productObject?.productStatus !== StatusTypes.DRAFT && productObject?.publiclyAvailable && !product.publiclyAvailable"
          :headline="t('product.info.headline')"
          :copy="t('product.info.copy')"
          class="mb-8"
        />
        <v-text-field
          v-model="product.name"
          :autofocus="!focusDesc"
          :label="t('product.form.productNameLabel')"
          :rules="productNameRules"
          required
          variant="outlined"
        >
          <template #append-inner>
            <v-icon size="10">{{ Icons.FAVORITE }}</v-icon>
          </template>
        </v-text-field>
        <v-textarea
          v-model="product.description"
          :label="t('product.form.productDescriptionLabel')"
          :rules="productDescriptionRules"
          required
          variant="outlined"
          counter="240"
          :autofocus="focusDesc"
        >
          <template #append-inner>
            <v-icon size="10">{{ Icons.FAVORITE }}</v-icon>
          </template>
        </v-textarea>
      </template>
      <template #buttons>
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :loading="loading"
          :disabled="!createProductForm"
        >
          {{ t('buttons.save') }}
        </DefaultButton>

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
        >
          {{ t('buttons.cancel') }}
        </DefaultButton>
      </template>
    </DialogDefaultLayout>
  </v-form>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, ref, PropType, Ref } from 'vue'
import { ProductForm, ProductResponse } from '@/models/Product'
import { isNotEmpty, isMinLength, isMaxLength } from '@/validators'
import { Icons } from '@/models/enums/IconTypes'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import InfoBox from '../InfoBox.vue'
import { StatusTypes } from '@/models/enums/StatusTypes'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'

const { t } = useI18n()

const componentProps = defineProps({
  productObject: {
    type: Object as PropType<ProductResponse>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  focusDesc: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel'])

const createProductForm = ref(false)
const productForm = ref<HTMLFormElement>()
const productNameRules = computed(() => [
  (v: string): boolean | string => isNotEmpty(v),
  (v: string): boolean | string => isMinLength(v, 3),
  (v: string): boolean | string => isMaxLength(v, 50),
])
const productDescriptionRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 240)])

const product: Ref<ProductForm> = ref({
  name: componentProps.productObject?.name || '',
  description: componentProps.productObject?.description || '',
  publiclyAvailable: componentProps.productObject?.publiclyAvailable || true,
  productStatus: StatusTypes.DRAFT,
})
</script>
<style lang="scss" scoped>
.availability-selector {
  :deep(.v-card-item) {
    width: 100%;
    padding: 0.825rem 0.825rem 1.5rem;
  }

  :deep(.v-card-item__append),
  :deep(.v-card-item__prepend) {
    align-self: start;
  }
}
</style>

<i18n lang="yaml">
de:
  product:
    create:
      headline: Neue API erstellen
      copy: Deine API wird zunächst als Entwurf gespeichert. Im nächsten Schritt kannst du weitere Informationen hinzufügen und die API veröffentlichen.
    edit:
      headline: API bearbeiten
    public:
      headline: öffentlich
      tooltip: Wenn du dich für eine öffentliche API entscheidest, ist sie für jeden auf dem Marktplatz zugänglich.
    private:
      headline: privat
      tooltip: Wenn du dich für eine private API entscheidest, musst du deine Kunden aktiv einladen, damit sie deine API auf dem Marktplatz sehen können.
    form:
      productNameLabel: Name
      productDescriptionLabel: Kurzbeschreibung
    info:
      headline: Aktive Nutzer werden automatisch freigegeben.
      copy: Private API-Produkte müssen für Organisationen freigegeben werden, um auf dem Marktplatz sichtbar zu sein.
</i18n>
