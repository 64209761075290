<template>
  <div class="product-details">
    <v-form @submit.prevent="emit('submit', detailedDescription, colour)">
      <DialogDefaultLayout>
        <template #content>
          <h1>{{ t('productDetail.form.headline') }}</h1>
          <div class="product-details__detailed-description">
            <p class="highlight">{{ t('productDetail.form.productDetailDescriptionLabel') }}</p>
            <MdEditor
              v-model="detailedDescription"
              language="en-US"
              :toolbars="mdToolbars"
              :footers="mdFooter"
              :custom-icon="customIcon"
              :data-cy="DialogProductFormDetailTest.DESCRIPTION"
              no-iconfont
              :preview="false"
              :style="{ height: '300px' }"
            />
          </div>
          <div class="product-details__identity">
            <p class="highlight">{{ t('productDetail.form.identity.headline') }}</p>
            <v-item-group
              v-model="colour"
              mandatory
              class="product-details__identity-group"
              :data-cy="DialogProductFormDetailTest.COLOR_CONTAINER"
            >
              <v-item
                v-for="color in getProductColorList()"
                :key="color"
                v-slot="{ toggle }"
                :value="color"
              >
                <v-btn
                  :color="color"
                  icon
                  :class="[colour === color ? 'product-details__identity--choosen' : '']"
                  :data-cy="`${DialogProductFormDetailTest.COLOR}-${color}`"
                  @click="toggle"
                >
                </v-btn>
              </v-item>
            </v-item-group>
          </div>
        </template>
        <template #buttons>
          <DefaultButton
            type="submit"
            :color="PlattformColors.PRIMARY"
            :loading="loading"
            :data-cy="DialogProductFormDetailTest.SUBMIT"
          >
            {{ t('buttons.save') }}
          </DefaultButton>

          <DefaultButton
            :color="PlattformColors.SECONDARY"
            @click="emit('cancel')"
          >
            {{ t('buttons.cancel') }}
          </DefaultButton>
        </template>
      </DialogDefaultLayout>
    </v-form>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ref, PropType } from 'vue'
import { ProductResponse } from '@/models/Product'
import { MdEditor, Footers, ToolbarNames, CustomIcon } from 'md-editor-v3'
import 'md-editor-v3/lib/style.css'
import { VIcon } from 'vuetify/lib/components/index.mjs'
import { Icons } from '@/models/enums/IconTypes'
import { h } from 'vue'
import { DialogProductFormDetailTest } from '@/models/enums/test/DialogProductFormDetailTest'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import { useProductHelper } from '@/composables/useProductHelper'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const mdToolbars: Array<ToolbarNames | number> = ['bold', 'italic', 'title', 'quote', 'orderedList', 'unorderedList', 'code', 'link', '=', 'preview']
const mdFooter: Array<Footers | number> = []

const customIcon: CustomIcon = {
  bold: { component: h(VIcon, { icon: Icons.BOLD, class: 'mx-1' }) },
  italic: { component: h(VIcon, { icon: Icons.ITALIC, class: 'mx-1' }) },
  title: { component: h(VIcon, { icon: Icons.H1, class: 'mx-1' }) },
  quote: { component: h(VIcon, { icon: Icons.QUOTE, class: 'mx-1' }) },
  'unordered-list': { component: h(VIcon, { icon: Icons.UNORDERED_LIST, class: 'mx-1' }) },
  'ordered-list': { component: h(VIcon, { icon: Icons.ORDERED_LIST, class: 'mx-1' }) },
  code: { component: h(VIcon, { icon: Icons.CODE, class: 'mx-1' }) },
  link: { component: h(VIcon, { icon: Icons.LINK_ALT, class: 'mx-1' }) },
  preview: { component: h(VIcon, { icon: Icons.EYE, class: 'mx-1' }) },
}

const { t } = useI18n()

const { getProductColorList } = useProductHelper()

const componentProps = defineProps({
  productObject: {
    type: Object as PropType<ProductResponse>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel'])

const detailedDescription = ref(componentProps.productObject?.detailedDescription || '')
const colour = ref<string | undefined>(componentProps.productObject?.colour)
</script>
<style lang="scss" scoped>
.product-details {
  &__detailed-description {
    margin-bottom: 2rem;
  }

  &__identity {
    margin-bottom: 2rem;

    &-group {
      display: flex;
      justify-content: center;

      .v-btn {
        margin-right: 1rem;
      }

      .v-item--active {
        box-shadow: 0 0 0 5px rgb(var(--v-theme-secondary));
      }
    }

    &--choosen {
      border: 4px solid rgb(var(--v-theme-secondary));
    }
  }
}
</style>

<i18n lang="yaml">
de:
  productDetail:
    form:
      headline: Produktbeschreibung
      productDetailDescriptionLabel: Produktbeschreibung
      identity:
        headline: Visuelle Identität
</i18n>
