<template>
  <v-menu
    transition="slide-y-transition"
    :offset="[0]"
    class="select-box"
  >
    <template #activator="{ props, isActive }">
      <DefaultButton
        v-bind="props"
        class="text-left mb-2"
        :color="PlattformColors.PRIMARY"
        :ripple="false"
      >
        <template v-if="activeOrganization || activeTenant">
          <div class="select-box__icon bg-white">
            <h2 class="select-box__icon-letter text-primary mt-4">
              {{ getFirstLetterUpperCase(activeOrganization?.name || activeTenant?.displayName) }}
            </h2>
          </div>
          <div class="select-box__organization">
            <span class="select-box__organization--name">{{ activeOrganization?.name || activeTenant?.displayName }} </span>
            <span class="select-box__organization--role"> {{ organizationRole }} </span>
          </div>
        </template>
        <span v-else>{{ t('selectBox.choose') }}</span>
        <v-icon :class="[{ 'rotate-180': isActive }, 'ml-2']">{{ Icons.CHEVRON_DOWN }}</v-icon>
      </DefaultButton>
    </template>
    <v-list class="select-box__list bg-primary-lighten-1">
      <v-list-item
        v-for="(item, i) in myOrganizations"
        :key="i"
        variant="flat"
        class="bg-transparent"
        @click="switchOrganization(item)"
      >
        <template #prepend>
          <div class="select-box__icon bg-white">
            <h2 class="select-box__icon-letter text-primary-lighten-2 mt-4">
              {{ getFirstLetterUpperCase(item.name) }}
            </h2>
          </div>
        </template>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        class="v-list-item__create"
        @click="createOrganizationDialogRef?.open()"
      >
        <template #prepend>
          <div class="v-list-item__create-icon">
            <v-icon>{{ Icons.ADD }}</v-icon>
          </div>
        </template>
        <v-list-item-title>
          {{ t('buttons.create') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider color="black" />
  </v-menu>

  <Dialog
    ref="createOrganizationDialogRef"
    @on-close="createOrganizationDialogRef?.close()"
  >
    <DialogOrganizationForm
      :loading="loading"
      @cancel="createOrganizationDialogRef?.close()"
      @submit="(organizationForm: OrganizationForm) => createOrganization(organizationForm)"
    />
  </Dialog>

  <Dialog
    ref="createdOrganizationDialogRef"
    :show-close-btn="false"
  >
    <DialogOrganizationCreated @submit="handleOverlayClick()" />
  </Dialog>
</template>

<script lang="ts" setup>
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { useTenantStore } from '@/store/tenants'
import { ComputedRef, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useLoadingStore } from '@/store/loading'
import { storeToRefs } from 'pinia'

import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import { AuthorizationsContexts } from '@/models/enums/AuthorizationsContextTypes'
import { useActiveUserHelper } from '@/composables/useActiveUserHelper'
import { UserOrganization } from '@/models/User'
import { useFormatHelper } from '@/composables/useFormatHelper'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import Dialog from '@/components/layout/Dialog.vue'
import DialogOrganizationForm from '@/components/dialogs/DialogOrganizationForm.vue'
import DialogOrganizationCreated from '@/components/dialogs/DialogOrganizationCreated.vue'
import { OrganizationForm } from '@/models/Organization'

const { t } = useI18n()
const router = useRouter()
const myOrganizationStore = useMyOrganizationStore()
const tenantStore = useTenantStore()
const loadingStore = useLoadingStore()

const { activeOrganization, myOrganizations } = storeToRefs(myOrganizationStore)
const { activeTenant } = storeToRefs(tenantStore)

const { isUserAdmin } = useActiveUserHelper()
const { getFirstLetterUpperCase } = useFormatHelper()

const organizationRole: ComputedRef<string> = computed(() => {
  return isUserAdmin() ? t('organizationRole.admin') : t('organizationRole.member')
})

const createOrganizationDialogRef = ref<InstanceType<typeof Dialog>>()
const createdOrganizationDialogRef = ref<InstanceType<typeof Dialog>>()

const loading = ref(false)

/**
 * switchOrganization
 * @param {UserOrganization} userOrganization
 */
async function switchOrganization(userOrganization: UserOrganization): Promise<void> {
  try {
    if (activeOrganization?.value?.id !== userOrganization.organizationId) {
      loadingStore.setAppLoading(true)

      if (userOrganization.authorizations[0].contexts.some((context) => context.context === AuthorizationsContexts.TENANT)) {
        await tenantStore.setActiveTenant(userOrganization.organizationId)
      } else {
        await myOrganizationStore.setActiveOrganization(userOrganization.organizationId)
      }

      await router.push({ name: Routes.INDEX })
    }
  } catch {
    Promise.resolve()
  } finally {
    loadingStore.setAppLoading(false)
  }
}

/**
 * createOrganization
 * @param {OrganizationForm} organizationForm
 */
async function createOrganization(organizationForm: OrganizationForm): Promise<void> {
  try {
    await myOrganizationStore.CREATE_AND_SELECT({ ...organizationForm, provider: false })
    createOrganizationDialogRef.value?.close()
    createdOrganizationDialogRef.value?.open()
  } catch {
    Promise.resolve()
  } finally {
    loading.value = false
  }
}

/**
 * handleOverlayClick
 */
function handleOverlayClick(): void {
  createdOrganizationDialogRef.value?.close()
  router.push({ name: Routes.HOME })
}
</script>

<style lang="scss" scoped>
@use 'sass:map';

.select-box {
  display: block;
  width: 100%;
  transition: 0.15s ease;

  &__button {
    height: 5rem;
    padding: 1rem;
    font-size: $font-size-m;
    width: 100%;

    span {
      display: block;
      text-align: left;
    }

    &[aria-expanded='true'] {
      .v-icon {
        transform: rotateX(180deg);
      }
    }
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.8rem;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }

  &__organization {
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &--name {
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 9.7rem;
      @media only screen and (max-width: #{map.get($container-max-widths, 'xs')}) {
        width: calc(100vw - 5rem);
      }
    }

    &--role {
      font-size: 0.625rem;
    }
  }

  &__list {
    font-size: $font-size-m;
    margin-top: 0.6rem;
  }
}

.create-organization--btn {
  display: flex;
  height: 2rem;
}

.v-menu {
  .v-list-item {
    min-height: auto;
    padding: 0.4rem 0.8rem;

    &-title {
      font-size: $font-size-m;
    }

    &__create-icon {
      height: 2rem;
      width: 2rem;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.8rem;
    }
  }
}
</style>
<i18n lang="yaml">
de:
  selectBox:
    choose: Organisation auswählen
  organizationRole:
    admin: Admin
    member: Mitglied
</i18n>
