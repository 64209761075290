<template>
  <v-card flat>
    <v-card-item class="pa-6">
      <template
        v-if="$slots.prepend"
        #prepend
      >
        <div class="mr-4">
          <slot name="prepend" />
        </div>
      </template>

      <template
        v-if="$slots.title"
        #title
      >
        <h3 class="mb-1"><slot name="title" /></h3>
      </template>

      <template
        v-if="$slots.subtitle"
        #subtitle
      >
        <span class="navigation-teaser__subtitle">
          <slot name="subtitle" />
        </span>
      </template>

      <template
        v-if="$slots.append"
        #append
      >
        <slot name="append" />
      </template>
    </v-card-item>
  </v-card>
</template>

<style lang="scss" scoped>
.navigation-teaser {
  &__subtitle {
    font-size: $font-size-xs;
  }
}
</style>
