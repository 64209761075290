<template>
  <div
    v-if="products?.length"
    class="product-list w-100"
  >
    <v-list
      v-if="view === ViewTypes.LIST || view === ViewTypes.LISTCOMFORTABLE"
      class="product-list py-0 bg-transparent"
    >
      <template
        v-for="(product, i) in products"
        :key="product.id"
      >
        <v-divider
          v-if="view === ViewTypes.LIST && i > 0"
          inset
        />
        <v-list-item
          :class="[view === ViewTypes.LISTCOMFORTABLE ? 'mb-4 pa-6' : 'py-2 my-4', 'bg-white']"
          :data-cy="ProductListTest.LIST_ITEM"
          @click="emit('handleProductClick', product.id)"
        >
          <template #prepend>
            <DefaultAvatar
              :size="view === ViewTypes.LISTCOMFORTABLE ? '48' : '40'"
              :class="view === ViewTypes.LISTCOMFORTABLE ? 'mr-6' : 'mr-4'"
            >
              <v-icon
                color="primary"
                :size="view === ViewTypes.LISTCOMFORTABLE ? '24' : '20'"
                >{{ Icons.PRODUCT }}</v-icon
              >
            </DefaultAvatar>
          </template>
          <template #title>
            <h3
              v-if="view === ViewTypes.LISTCOMFORTABLE"
              class="mb-0"
            >
              {{ product.name }}
            </h3>
            <p
              v-else
              class="highlight mb-1"
            >
              {{ product.name }}
            </p>
          </template>
          <template #subtitle>
            <p class="mb-0 xs">
              <template v-if="!ViewTypes.LISTCOMFORTABLE && (product as ProductResponse).organization.name">
                <span>{{ (product as ProductResponse).organization.name }}</span>
                <v-divider
                  vertical
                  length="12"
                  thickness="1"
                  color="black"
                  opacity=".5"
                  class="mx-2"
                />
              </template>

              <span
                v-if="product.createdAt"
                class="date"
                >{{
                  t('createdAt', {
                    d: format(new Date(product.createdAt), 'dd.MM.yyyy'),
                  })
                }}</span
              >
            </p>
          </template>

          <template #append>
            <div>
              <StatusChip
                v-if="(product as ProductResponse).productStatus === StatusTypes.DRAFT"
                class="mr-4"
                :color="getStatusSetFromStatusKey(StatusTypes.DRAFT)?.color"
                :icon="getStatusSetFromStatusKey(StatusTypes.DRAFT)?.icon"
              >
                {{ t('productList.draftStatus') }}
              </StatusChip>
              <v-icon v-if="product.publiclyAvailable">{{ Icons.GLOBE }}</v-icon>
              <v-icon v-else>{{ Icons.LOCK }}</v-icon>
            </div>
          </template>
        </v-list-item>
      </template>
    </v-list>

    <div
      v-else
      class="d-flex flex-wrap mx-n4"
    >
      <ProductTile
        v-for="product in products"
        :key="product.id"
        transition="scale-transition"
        :product="product as ProductResponse"
        :data-cy="ProductListTest.TILE_ITEM"
        @click="emit('handleProductClick', product.id)"
      />
    </div>
  </div>
  <div v-else>
    <p
      class="mt-6"
      :class="{ 'ml-16 pl-4': view === ViewTypes.LIST }"
      :data-cy="ProductListTest.NO_PRODUCTS"
    >
      {{ t('productList.noProducts') }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { format } from 'date-fns'
import { useI18n } from 'vue-i18n'
import { ProductResponse } from '@/models/Product'
import { Icons } from '@/models/enums/IconTypes'
import { ViewTypes } from '@/models/enums/ViewTypes'
import ProductTile from '@/components/products/ProductTile.vue'
import { PropType } from 'vue'
import { ProductListTest } from '@/models/enums/test/ProductListTest'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { useStatusHelper } from '@/composables/useStatusHelper'
import StatusChip from '../StatusChip.vue'
import { BundleProductResponse } from '@/models/Bundle'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'

const { t } = useI18n()

const { getStatusSetFromStatusKey } = useStatusHelper()

const emit = defineEmits(['handleProductClick'])

defineProps({
  products: {
    type: Array<ProductResponse | BundleProductResponse>,
    default: undefined,
  },
  view: {
    type: String as PropType<ViewTypes>,
    default: ViewTypes.LIST,
  },
})
</script>

<i18n lang="yaml">
de:
  productList:
    noProducts: Keine API-Produkte gefunden.
    draftStatus: Entwurf
</i18n>
