import { createVuetify } from 'vuetify'

// Styles
import 'vuetify/styles'
import '../assets/iconfont/icons.css'

import { aliases, wallisIcons } from '../iconsets/wallisIcons'
import { VBtn } from 'vuetify/lib/components/index.mjs'

const vuetify = createVuetify({
  aliases: {
    VBtnProgress: VBtn,
  },
  defaults: {
    VBtnProgress: { variant: 'flat', size: 'large', class: ['v-btn--progress'] },
  },
  icons: {
    defaultSet: 'wallisIcons',
    sets: {
      wallisIcons,
    },
    aliases,
  },

  theme: {
    defaultTheme: 'light',
    variations: {
      colors: ['primary'],
      lighten: 3,
      darken: 0,
    },
    themes: {
      light: {
        colors: {
          primary: '#01325a',
          secondary: '#F5F5F5',
          blue: '#0071D4',
          green: '#28B664',
          yellow: '#F39C12',
          red: '#C01212',
          black: '#080809',
          white: '#fffffff',
          'light-grey': '#CCD6DE',
        },
      },
    },
  },
  display: {
    thresholds: {
      xs: 0,
      sm: 600,
      md: 960,
    },
    mobileBreakpoint: 'md',
  },
})

export default vuetify

/**
 * setPrimaryColor
 * @param {string} primaryColor
 */
export function setPrimaryColor(primaryColor: string): void {
  vuetify.theme.themes.value.light.colors.primary = primaryColor
}
