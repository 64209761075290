<template>
  <div
    v-if="product?.detailedDescription"
    class="content-container"
  >
    <div class="d-flex justify-space-between">
      <h2>{{ t('providerProductDetail.headline') }}</h2>

      <IconButton
        :icon="Icons.EDIT"
        @click="detailedDescriptionDialogRef?.open()"
      />
    </div>
    <MdPreview
      v-model="product.detailedDescription"
      language="en-US"
      no-iconfont
    />
  </div>
  <div v-else>
    <CreateTeaser
      :icon="Icons.ORDERED_LIST"
      :headline="t('providerProductDetail.teaser.headline')"
      :copy="t('providerProductDetail.teaser.copy')"
      :btn-text="t('providerProductDetail.teaser.button')"
      @submit="detailedDescriptionDialogRef?.open()"
    />
  </div>
  <Dialog
    ref="detailedDescriptionDialogRef"
    @on-close="detailedDescriptionDialogRef?.close()"
  >
    <DialogProductFormDetail
      :product-object="product"
      :loading="loading"
      @cancel="detailedDescriptionDialogRef?.close()"
      @submit="(detailedDescription: string, colour?: string) => updateProduct(detailedDescription, colour)"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { MdPreview } from 'md-editor-v3'
import { ref } from 'vue'
import { useProductStore } from '@/store/products'
import { useAlertStore } from '@/store/alerts'
import { AlertTypes } from '@/models/enums/AlertTypes'
import CreateTeaser from '@/components/layout/CreateTeaser.vue'
import { Icons } from '@/models/enums/IconTypes'
import Dialog from '@/components/layout/Dialog.vue'
import DialogProductFormDetail from '@/components/dialogs/DialogProductFormDetail.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'

const { t } = useI18n()

const productStore = useProductStore()
const alertStore = useAlertStore()
const { product } = storeToRefs(productStore)

const loading = ref(false)
const detailedDescriptionDialogRef = ref<InstanceType<typeof Dialog>>()

/**
 * updateProduct
 * @param {string} detailedDescription
 * @param {string} colour
 */
async function updateProduct(detailedDescription: string, colour?: string): Promise<void> {
  if (product?.value) {
    try {
      loading.value = true
      await productStore.UPDATE({ detailedDescription, colour }, product.value.id)
      alertStore.add({
        text: t('providerProductDetail.update.success'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      return Promise.resolve()
    } finally {
      loading.value = false
      detailedDescriptionDialogRef.value?.close()
    }
  }
}
</script>

<i18n lang="yaml">
de:
  providerProductDetail:
    headline: Produktbeschreibung
    update:
      success: Deine API wurde erfolgreich aktualisiert!
    teaser:
      headline: Was macht deine API aus?
      copy: Füge eine Beschreibung hinzu, um deine API zu spezifizieren.
      button: Beschreibung hinzufügen
</i18n>
