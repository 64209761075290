export enum ProductListWithPlansTest {
  ITEM = 'product-list-plans-item',
  CHEVRON = 'product-list-plans-item-chevron',
  INFO = 'product-list-plans-item-info',
  INFO_TERMINATION = 'product-list-plans-subscription-in-termination',
  INFO_REQUESTED = 'product-list-plans-subscription-requested',
  TERMINATE = 'product-list-plans-subscription-terminate',
  SUBSCRIPTION_CONTAINER = 'product-list-plans-subscription-container',
  SUBSCRIPTION = 'product-list-plans-subscription-item',
}
